// -----------------------------------------------------------------------------
// This file contains all styles related to the scroll slider component.
// -----------------------------------------------------------------------------
.main-careers {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100vw;
    min-height: 100vh;

    .controls {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        min-height: 70px;
        position: absolute;
        top: 40vh;
    }

    .slides-container {
        position: relative;
        display: flex;
        flex: 1;

        .main-careers__img {
            width: 100%;
            height: 50vh;
        }

        .slide {
            position: absolute;
            display: flex;
            justify-content: center;
            height: 100%;
            width: calc(100% / 3);
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .slides-inner {
            position: relative;
            height: 100%;
            width: 100%;
        }

        .main-careers__text {
            min-height: 50vh;

            .colour-blue {
                color: #0055FA;
            }
        }
    }
}

.careers-overlays-container {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: none;
    animation: slide-up-fade-in 1 ease-in;
    transition: background 2s ease-in;

    .careers-overlay {
        position: absolute;
        width: 100%;
        z-index: 3;
        height: 100%;
        top: 0;
        display: none;
        text-align: center;
        animation: slide-up-fade-in ease 1.5s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        animation-fill-mode: forwards;
        opacity: 0;
    }

    .overlay-blue {
        background-color: rgb(0 85 250 / 80%);
    }

    .overlay-yellow {
        background-color: rgb(234 255 0 / 80%);
    }

    .overlay-red {
        background-color: rgb(255 87 98 / 80%);
    }
}

.career-title {
    &:hover {
        .career-title-arrow {
            display: inline;
        }
    }

    .career-title-arrow {
        max-width: 0.6em;
        margin-left: 1rem;
        display: none;
    }
}

@-webkit-keyframes slide-up-fade-in {
    0% {
        opacity: 0;
        transform: translate(0px,40px);
    }

    100% {
        opacity: 1;
        transform: translate(0px,0px);
    }
}

@keyframes slide-up-fade-in {
    0% {
        opacity: 0;
        transform: translate(0px,40px);
    }

    100% {
        opacity: 1;
        transform: translate(0px,0px);
    }
}