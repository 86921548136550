// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.blog-wrapper {
    h1, h2, h3, h4, h5, h6 {
        font-family: "SofiaPro-SemiBold",sans-serif;
        line-height: 1.4em;
        margin-bottom: 1em;
    }

    h2{
        font-size: 1.35rem;
        margin-bottom: 1.1rem;
    }

    p {
        font-family: "SofiaPro-Light",sans-serif;
        line-height: 1.4em;
        margin-bottom: 0;
        font-size: 1.1rem;
    }

    a:hover {
        text-decoration: none;

        h2 {
            text-decoration: underline;
        }
    }

    .tag-text {
        font-size: 1rem;
        color: #222;
    }
}

.blog-header {
    .author-image {
        border-radius: 100%;
        max-height: 70px;
    }

    .author-name {
        font-size: 1.2rem;
    }

    .publish-date {
        &:after {
            content: "\2219";
            padding-left: 0.3em;
            padding-right: 0.3em;
        }
    }

    p {
        font-family: "SofiaPro-Light",sans-serif;
        line-height: 1.6em;
        margin-bottom: 0;
        font-size: 1.2rem;
    }

    .container {
        @media (min-width: 768px) {
            margin-bottom: 15vh
        }
    }

    .category-header {
        font-size: 1.4rem;
        color: $yellow;
    }
}

.post-content {
    @media (min-width: 768px) {
        position: relative;
        z-index: 5;
        margin-top: -20vh !important;
    }

    p {
        font-family: "SofiaPro-Light",sans-serif;
        line-height: 1.6em;
        margin-bottom: 1em;
        font-size: 1.2rem;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: "SofiaPro-Bold",sans-serif;
        line-height: 1.6em;
        margin-bottom: 1em;
    }
}

.load-more {
    position: absolute;
    left: 48.7vw;
}
