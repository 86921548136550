// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.not-found {
    margin: 0;
    text-align: center;
    height: 100vh;
    width: 100vw;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;

    h1 {
        margin: 0;
        margin-top: 24px;
        margin-bottom: 8px;
        color: #fff;

        @media (min-width: 768px) {
            font-size: 10em;
        }
    }

    .display-6 {
        color: #fff;
    }
}