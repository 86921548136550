// -----------------------------------------------------------------------------
// This file contains all styles related to the video modal component.
// -----------------------------------------------------------------------------
.accordion {
    .accordion-collapse {
        display: block;
        visibility: hidden;
        transition: visibility .5s, max-height .5s;
        max-height: 0px;
        overflow: hidden;

        &.show {
            visibility: visible;
            /* Set max-height to something bigger than the box could ever be */
            max-height: 500px;
        }
    }
}
