// -----------------------------------------------------------------------------
// This file contains all styles related to the scroll slider component.
// -----------------------------------------------------------------------------
.scroll-content-wrapper {
    .scroll-content {
        @media (min-width: 768px) {
            position: absolute;
        }
    }

    .content-wrapper {
        overflow: hidden;
        position: relative;

        .content {
            position: relative;
            padding: 0 20px;
        }
    }

    .slide-pip {
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);

        &.active {
            border-bottom: 2px solid rgb(255, 255, 255);
        }
    }
}
