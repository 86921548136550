// -----------------------------------------------------------------------------
// This file contains all styles related to the video modal component.
// -----------------------------------------------------------------------------
.post-content {
    h2, h3, h4, h5 {
        margin-bottom: 1em
    }

    p {
        line-height: 1.6em;
        margin-bottom: 1em;
    }

    p, ul {
        font-family: SofiaPro-Light;
    }

    a {
        color: #0a58ca;
        font-weight: bold;
    }
}