// -----------------------------------------------------------------------------
// This file contains all styles related to the scroll slider component.
// -----------------------------------------------------------------------------
.slides-wrapper {
    position: relative;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;

    .scroll-slide {
        min-height: 100vh;
        width: 100vw;

        .slides-text {
            @media (min-width: 768px) {
                top: 50%;
                transform: translate(0%, -50%);
                position: absolute;
            }

            .active {
                visibility: visible;
            }
        }

        @media (max-width: 767px) {
            transform: translate(0%, 0%) !important;
        }

        @media (min-width: 768px) {
            height: 100vh;
            position: absolute;
        }
    }
}

.eli5 {
    background-color: #000;

    .eli5-top {
        @media (min-width: 768px) {
            min-height: 50vh;
            width: 100%;
        }
    }
}



.scroll-collection, .scroll-single {
    .scroll-container {

        @media (min-width: 768px) {
            max-height: 100vh;
        }
    }

    .scroll-vh {

        @media (min-width: 768px) {
            min-height: 115vh;
        }
    }

    .scroll-info {
        @media (max-width: 767px) {
            visibility: visible !important;
            opacity: 1 !important;
            display: flex !important;
        }

        @media (min-width: 768px) {
            height: 100vh;
        }
    }
}
