// -----------------------------------------------------------------------------
// This file contains all styles related to the video modal component.
// -----------------------------------------------------------------------------
@media (min-width: 768px) {
    .perk-container {
        height: 80vh;
        overflow: hidden;
    }
}

.perk {
    p {
        margin: 0;
    }
}