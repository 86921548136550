﻿

.flipbook { position: relative; z-index: 5;

    p { font-family: "SofiaPro-Light",sans-serif; line-height: 1.6em; margin-bottom: 1em; font-size: 1.2rem; }

    h1,
    h2, 
    h3,
    h4,
    h5, 
    h6 { font-family: "SofiaPro-Bold",sans-serif; line-height: 1.6em; margin-bottom: 1em; }
    

    &_book { position: relative; height: calc(100vh - 150px); min-height: 300px;

        svg { position: absolute; width: 100px; top: 50%; left: 50%; transform: translate(-50%, -50%); }

    }


    &--pull-up { margin-top: -100px; }


    @media screen and (min-width: 768px) {

        &--pull-up {
            margin-top: -180px;
        }

    }
}