// -----------------------------------------------------------------------------
// This file contains all styles related to the scroll slider component.
// -----------------------------------------------------------------------------
.scroll-expanding {
    background-repeat: no-repeat;
    background-position: center;

    .container-fluid {
        position: relative;
        height: 100vh;
    }
}
