// -----------------------------------------------------------------------------
// This file contains all styles related to the video modal component.
// -----------------------------------------------------------------------------
.background-hero {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.statement-wrapper {
    max-width: 80vw;
}

@media (min-width: 1340px) {
    .inner-animate {
        font-size: 1.3em;
    }
}


@media (min-width: 768px) {
    .scroll-collection-row {
        min-height: 120vh;
    }
}
