// -----------------------------------------------------------------------------
// This file contains all styles related to the video modal component.
// -----------------------------------------------------------------------------
.two-col-image {
    .two-col-image-row {
        min-height: 50vh;

        img {
            height: auto
        }
    }
}
