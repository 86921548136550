// -----------------------------------------------------------------------------
// This file contains all styles related to the video modal component.
// -----------------------------------------------------------------------------
.image-with-text-hero {
    z-index: 5;
    position: relative;
    height: auto;

    .container-fluid.top-text {
        position: relative;
        z-index: 1;

        .row-top {
            min-height: 100vh;
            height: auto;
            background-color: white;

            .join-text {
                height: 100vh;

                @media (max-width: 767px) {
                    height: auto;
                }
            }
        }
    }
}