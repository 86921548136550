/**
 * Basic typography style for copy text
 */

body {
    font-family: "SofiaPro-SemiBold",sans-serif, var(--bs-font-sans-serif) !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.fw-bold {
    font-family: "SofiaPro-Bold",sans-serif;
}

.fw-light {
    font-family: "SofiaPro-Light",sans-serif;
}
