// -----------------------------------------------------------------------------
// This file contains all styles related to the video modal component.
// -----------------------------------------------------------------------------
.cta-wimg-block {
    .scroll-pin {
        position: relative;
    }

    .center-block {
        margin: 0 auto;
    }

    .yellow-bar-wrapper {
        position: absolute;
        top: 25%;
        left: -100%;
        width: 100%;

        .yellow-bar {
            border-bottom: 1px solid $yellow;
        }
    }
}
