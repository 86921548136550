// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.button {
    cursor: pointer;
    padding: 8px 16px;
    margin: 10px 5px;
}

button {
    outline: none;
}

.arrow-large img {
    max-width: 2em;
}

@media (min-width: 768px) {
    .arrow-large img {
        max-width: 4em;
    }
}

.blog-img-wrapper {
    position: relative;
    width: 100%;
    padding-top: 111.11%;
}

.blog-img-wrapper img {
    position: absolute;
    top: 0;
}

@media (min-width: 768px) {
    .blog-block-2 {
        padding-top: 3.5rem;
    }
}

.link-arrow {
    max-width: 0.7em;
    transform: translateY(-2px);
}

.text-black-50 {
    .link-arrow {
        opacity:0.5;
    }
}
