// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
    font-family: "SofiaPro-Light";
    src: url('../fonts/SofiaProLight.woff2') format('woff2'),url('../fonts/SofiaProLight.woff') format('woff');
}

@font-face {
    font-family: "SofiaPro-SemiBold";
    src: url('../fonts/SofiaProSemiBold.woff2') format('woff2'),url('../fonts/SofiaProSemiBold.woff') format('woff');
}

@font-face {
    font-family: "SofiaPro-Bold";
    src: url('../fonts/SofiaProBold.woff2') format('woff2'),url('../fonts/SofiaProBold.woff') format('woff');
}