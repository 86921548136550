// -----------------------------------------------------------------------------
// This file contains all styles related to the video modal component.
// -----------------------------------------------------------------------------
.feature-block {
    background: #0055FA;
    position: relative;
    min-height: 100vh;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;

    .feature {
        min-height: 100vh;
        width: 100vw;

        @media (min-width: 768px) {
            height: 100vh;
        }
    }
}

.two-col-image-text {
    h2 {
        margin-bottom: 1.5rem;
    }
}