// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footer {
    z-index: 5;

    .footer-text {
        font-size: 0.70rem;
        color: rgb(0 0 0 / 40%);
    }
}

.footer-background {
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;
}