// -----------------------------------------------------------------------------
// This file contains all styles related to the video modal component.
// -----------------------------------------------------------------------------
.single-listing {
    border-bottom: #b9b9b9 solid 1px;
}

.single-listing:first-child {
    border-top: #b9b9b9 solid 1px;
}

.single-listing .col-md-9 {
    padding: 0;
}

@media (min-width: 768px) {
    .single-listing .col-md-9 {
        padding-right: calc(var(--bs-gutter-x) * .5);
    }
}

.single-listing .col-md-3 {
    padding: 0;
}

@media (min-width: 768px) {
    .single-listing .col-md-3 {
        padding-left: calc(var(--bs-gutter-x) * .5);
    }
}
