// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
    box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
    box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
    color: $brand-color;
    text-decoration: none;

    @include on-event {
        color: $text-color;
        text-decoration: underline;
    }
}

* {
    box-sizing: border-box;
}

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*, ::after, ::before {
    box-sizing: border-box;
}

@keyframes expand {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fadeinImg {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }

    80% {
        transform: scaleY(1.1)
    }

    100% {
        transform: scaleY(1)
    }
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

body {
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #000;
    margin: 0;
}

html, body {
    height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.mix-mode {
    mix-blend-mode: exclusion;
}

a, a:visited {
    color: inherit;
    -webkit-text-decoration: none;
    text-decoration: none;
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-1 {
        font-size: 5rem;
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-2 {
        font-size: 4.5rem;
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-3 {
        font-size: 4rem;
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-4 {
        font-size: 3.5rem;
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-5 {
        font-size: 3rem;
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-6 {
        font-size: 2.5rem;
    }
}

.color-red {
    color: #FF5762;
}

.color-blue {
    color: #0055FA;
}

.color-yellow {
    color: $yellow;
}

.bg-black {
    background: #000;
}

.bg-red {
    background-color: #FF5762;
}

.bg-blue {
    background-color: #0055FA;
}

.bg-yellow {
    background-color: $yellow;
}

.bg-beige {
    background-color: #FAF3F1;
}

.wrapper, .container-fluid {
    overflow-x: hidden;
}

.mission {
    .container-fluid {
        position: relative;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;

        @media (min-width: 768px) {
            min-height: 50vh;

            &.intro-top-text {
                min-height: 33vh;
            }
        }
    }
}

.root-container {
    position: absolute;
    width: 100%;
}

.pin-spacer {
    overflow: hidden !important;
}

@media (max-width: 767px) {
    .height-90vh {
        height: 90vh;
    }
}

@media (min-width: 768px) {
    .max-80vw {
        max-width: 80vw;
    }
}

@media (min-width: 768px) {
    .max-40vh {
        max-width: 40vh;
        margin: 0 auto;
    }
}

@media (min-width: 768px) {
    .max-60vh {
        max-width: 60vh;
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
    .moveUp, .moveDown {
        margin-top: unset !important;
        margin-bottom: unset !important;
    }
}

.vacancy-message-container {
    background-color: #f4f4f4;
}

.top-overlay {
    z-index: 5;
}

.top-overlay .row-top {
    min-height: 100vh;
}

.img-block {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.join-text {
    height: 100vh;
}

@media (max-width: 767px) {
    .join-text {
        height: auto;
    }
}

.page-title {
    transform: rotate(-90deg) translateY(2.5px) translateX(-35px) !important;
}

.container-fluid.top-text {
    position: relative;
    z-index: 1;
}

.listings .container-fluid {
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
}

.top-border {
    border-top: #b9b9b9 solid 1px;
}

.btn.apply {
    border-radius: 0;
    text-transform: uppercase;
}

.arrow-small {
    width: 11px;
    transform: translate(5px, -1.5px);
}

.arrow-large img {
    max-width: 2em;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    float: right;
    margin: 8px;
    color: #9a9a9a;

    .state-label {
        position: absolute;
        left: -65px;
        line-height: 34px;
    }

    input {
        display: none;

        &[type=checkbox], &[type=radio] {
            box-sizing: border-box;
            padding: 0;
        }

        &:checked + .slider {
            background-color: #0055FA;

            &:before {
                transform: translateX(26px);
            }
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;

        &.round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }

        &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: #fff;
            transition: .4s;
        }
    }
}

.flex-basis-90 {
    flex-basis: 90%;
}

@media (min-width: 768px) {
    .arrow-large img {
        max-width: 4em;
    }

    .fs-md-5 {
        font-size: 1.25rem !important;
    }

    .fs-md-4 {
        font-size: 1.5rem !important;
    }

    .text-md-right {
        text-align: right;
    }
}

details {
    font-family: "SofiaPro-Light", sans-serif;
    position: relative;
    width: calc(150px + 3vw);
    z-index: 1;
    border-radius: 25px;
    padding: 0.7rem 1rem;
    background-color: #242424;

    input[type=radio] {
        counter-increment: radios;
        appearance: none;
        display: none;
        color: white;
    }

    summary:after {
        content: '';
        display: inline-block;
        margin-top: 5px;
        margin-right: 8px;
        float: right;
        width: .6rem;
        height: .6rem;
        border-bottom: 1px solid white;
        border-left: 1px solid white;
        border-bottom-left-radius: 2px;
        transform: rotate(-45deg) translate(0%, 0%);
        transform-origin: center center;
        transition: transform ease-in-out 100ms
    }

    &.default {
        color: #4D4D4D;
        background-color: #f0f0f0;
        border: 1px solid #DEDEDE;

        summary:after {
            border-color: #4D4D4D;
        }

        input[type=radio] {
            color: #4D4D4D;
        }
    }
}

details[open] {
    z-index: 1;
}

summary {
    cursor: pointer;
    border-radius: 25px;
    background-color: transparent;
    list-style: none;
}

summary::-webkit-details-marker {
    display: none;
}

details[open] summary:before {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
}

@media (min-width: 450px) {
    .w-sm-80 {
        width: 80% !important
    }
}

@media (min-width: 768px) {
    .w-md-80 {
        width: 80% !important;
    }
}

#search {
    font-family: "SofiaPro-Light", sans-serif;
    border-radius: 25px;
    padding: 0.72rem;

    @media (min-width: 768px) {
        min-width: 277px;
    }

    &::placeholder {
        color: #808080
    }
}

.search-button {
    background: #F0F0F0;
    border-radius: 100%;
    height: 47px;
    position: relative;
    width: 47px;
    right: 21px;
    top: 1px;
    cursor: pointer;
    position: absolute;

    svg {
        width: 1.4rem;
        height: 1.4rem;
        margin: 0.8rem;
        fill: #4D4D4D;
    }
}

summary:focus {
    outline: none;
}

details[open] summary:after {
    transform: rotate(-45deg) translate(0%, 0%);
}

.custom-select {
    ul {
        width: 100%;
        background: #f0f0f0;
        border: 1px solid #DEDEDE;
        position: absolute;
        top: calc(100% + .5rem);
        left: 0;
        padding: 1rem;
        margin: 0;
        box-sizing: border-box;
        border-radius: 5px;
        max-height: 200px;
        overflow-y: auto;
    }

    label {
        margin: 0;
        padding: 1rem 0;
        border-bottom: 1px solid #4D4D4D;
    }

    li:first-child label {
        padding-top: 0;
    }

    li:last-child label {
        padding-bottom: 0;
        border-bottom: none;
    }
    /* FAKE SELECT */

    summary.radios {
        counter-reset: radios;
    }

    input[type=radio]:checked {
        display: inline;
        z-index: -1;
        position: relative
    }

    input[type=radio]:after {
        content: attr(title);
        display: inline;
        font-size: 1rem;
        margin-left: 10px;
    }

    ul.list {
        counter-reset: labels;
        list-style-type: none;
    }

    label {
        width: 100%;
        display: block;
        cursor: pointer;
    }
}