// -----------------------------------------------------------------------------
// This file contains all styles related to the video modal component.
// -----------------------------------------------------------------------------
.video-modal {
    position: absolute;
    top: 0;
    display: none;

    .menu-top-video {
        position: fixed;
        right: 10px;
        top: 24px;
        background: transparent;
        border: 0px;
        color: white;
        z-index: 20;
        height: 40px;
        width: 40px;
        padding: 0;
    }

    .video-spacer {
        background-color: rgb(0, 0, 0);
        width: 100vw;
        height: 100vh;
        overflow: hidden auto;
        position: fixed;
        z-index: 15;
        top: 0px;
        left: 0px;
    }

    .video-wrapper {
        z-index: 16;
        position: fixed;
        background: rgb(0, 0, 0);
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        overflow-y: auto;

        .wrapper {
            position: relative;
            z-index: 3;
            background-color: rgb(0, 0, 0);
            transition: background-color 1s ease-out 0s;

            .container-fluid {
                min-height: 100vh;
                position: relative;
                z-index: 1;
            }

            .video-container {
                video {
                    width: 100%;
                    height: auto;
                    max-height: 100vh;
                }
            }
        }
    }
}

#bgVideo {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.playIcon {
    cursor: pointer;
}

.menu-top, .menu-top-video {
    padding: 0;
}

.video-overlay {
    .statement-animate {
        position: absolute;
        height: 100vh;
    }

    .img-block {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .row-top {
        background-color: rgb(0 85 250 / 80%);
    }

    .wrapper.top-overlay {
        height: 100vh;
        position: relative;
        z-index: 3;
    }

    .container-fluid.top-text {
        position: absolute;
        z-index: 1;
    }

    .top-overlay .row-top {
        height: 100vh;
    }

    .top-video .container-fluid {
        position: relative;
        height: 100vh;
        background: #111;
    }

    .wrapper .background-video {
        width: 80vw;
        height: 60vh;
    }
}
