// -----------------------------------------------------------------------------
// This file contains all styles related to the video modal component.
// -----------------------------------------------------------------------------
.cookie-banner {
    position: fixed;
    z-index: 100;
    background: black;
    width: 100vw;
    color: white;
    bottom: 0;
    opacity: 0.9;

    a:not(.btn) {
        text-decoration: underline;
    }
}