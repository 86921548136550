// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.logo {
    z-index: 9;
    top: 26px;
    left: 26px;
    position: fixed;

    svg {
        width: 75px;
    }

    g {
        .dareBolt {
            fill: $yellow;

            &:hover {
                fill: $yellow !important;
            }
        }

        .dareWord {
            fill: #fff;
        }

        &#Bolt {
            transform: translateX(-2.8em);

            @media (min-width: 768px) {
                transform: translateX(-1.9em);
            }
        }
    }

    &.logo-black {
        g {
            .dareWord {
                fill: #000;
            }
        }
    }
}

.menu-top {
    position: fixed;
    right: 10px;
    top: 24px;
    background: transparent;
    border: 0;
    color: white;
    z-index: 7;
    height: 40px;
    width: 40px;

    &.logo-black {
        g {
            fill: #000;
        }
    }
}

@media (max-width: 560px) {
    .menu-top {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.small-background-image {
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;
}

.social-wrapper {
    .social-icon {
        width: 2em;
        height: 2em;
        text-align: center;

        img {
            max-width: 1em;
            height: 1em;
        }
    }

    .contact-links {
        bottom: 13px;
        right: 17px;
        z-index: 4;
        overflow-x: unset;

        .contact-anchor {
            transform-origin: 0 0;
            transform: rotate(-90deg) translateY(2.5px) translateX(50px);
            position: absolute;
            white-space: nowrap;
            color: $yellow;
        }
    }
}

.nav-spacer {
    width: 100vw;
    height: 100vh;
    overflow: hidden auto;
    position: fixed;
    z-index: 2;
    top: 0px;
    left: 0px;
}

.nav-wrapper {
    z-index: 6;
    position: fixed;
    background: rgb(0, 0, 0);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    background-image: url('../images/BoltYellow.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: background-color 1s ease-in 0s;

    .wrapper {
        position: relative;
        z-index: 3;
        background-color: rgb(0, 0, 0);
        transition: background-color 1s ease-out 0s;

        .menu-links-top {
            min-height: 55vh;

            a {
                width: 100%;
                display: inline-block;
                outline: 0px;
                padding: 1.2rem 0px;
                text-decoration: none;

                &:hover, &:focus {
                    color: rgb(0, 0, 0);
                }

                &:hover[data-bg="black"], &:focus[data-bg="black"] {
                    color: rgb(234, 255, 0);
                }

                &[aria-current="page"] {
                    color: yellow;
                    font-weight: bold;
                }
            }
        }

        .container-fluid {
            min-height: 100vh;
            position: relative;
            z-index: 1;
        }

        .contact-links {
            position: fixed;
            bottom: 13px;
            right: 17px;
            z-index: 6;
            overflow-x: unset;

            .social-icon {
                width: 2em;
                height: 2em;
                text-align: center;

                img {
                    max-width: 1em;
                    height: 1em;
                }
            }
        }
    }

    .bg-bolt {
        background-image: url('../images/Bolt.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        transition: background-color 1s ease-in 0s;

        &.nav-item-container {
            background-size: contain;
        }
    }

    .bg-bolt-yellow {
        background-image: url('../images/BoltYellow.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        transition: background-color 1s ease-in 0s;

        &.nav-item-container {
            background-size: contain;
        }
    }

    .bg-red {
        background-color: rgb(255, 87, 98);
    }

    .bg-blue {
        background-color: rgb(0, 85, 250);
    }
}

.page-title-wrapper {
    mix-blend-mode: exclusion;
    z-index: 4;
    overflow-x: unset;
    top: 55%;
    left: 40px;
    position: absolute;
    text-transform: uppercase;
    visibility: hidden;

    &.page-title-fixed {
        position: fixed;
        visibility: visible;
        top: 140px;
        left: 4px;

        @media (min-width: 768px) {
            left: 26px;
        }
    }

    .page-title {
        transform: rotate(-90deg) translateY(2.5px) translateX(50px);
        transform-origin: 0 0;
        white-space: nowrap;
        color: $yellow;
        position: absolute;
    }
}

.nav-item-container p {
    margin-bottom: .5rem;
}