// -----------------------------------------------------------------------------
// This file contains all styles related to the scroll slider component.
// -----------------------------------------------------------------------------
.clouds {
    background-size: cover;
    background-repeat: no-repeat;

    .col {
        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    .container-fluid {
        position: relative;
        height: 105vh;
    }

    span {
        white-space: break-spaces;

        @media (max-width: 767px) {
            width: 100%;

            &.clouds-line {
                text-align: center;
            }

            &.clouds-line:first-child {
                text-align: left;
            }

            &.clouds-line:last-child {
                text-align: right;
            }
        }
    }
}
